import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={780.6}
    height={610.775}
    viewBox="0 0 31.224 24.431"
    {...props}
  >
    <path
      d="M.381 2.331C.125 2.075 0 1.75 0 1.356S.125.637.381.381.962 0 1.356 0h28.512c.394 0 .719.125.975.381s.381.581.381.975-.125.719-.381.975-.581.381-.975.381H1.356c-.394 0-.719-.125-.975-.381Zm30.462 8.913c.256.256.381.581.381.975s-.125.719-.381.975-.581.381-.975.381H1.356c-.394 0-.719-.125-.975-.381S0 12.613 0 12.219s.125-.719.381-.975.581-.381.975-.381h28.512c.394 0 .719.125.975.381zm0 10.856c.256.256.381.581.381.975s-.125.719-.381.975-.581.381-.975.381H1.356c-.394 0-.719-.125-.975-.381S0 23.469 0 23.075s.125-.719.381-.975.581-.381.975-.381h28.512c.394 0 .719.131.975.381z"
      style={{
        fill: "#ff5c00",
        fillOpacity: 1,
      }}
    />
  </svg>
)

export default SvgComponent
