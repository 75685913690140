import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={97.046}
    height={97.047}
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M255.193 426h47.657a18.372 18.372 0 0 1 4.425.548 19.09 19.09 0 0 1 1.701.517 18.085 18.085 0 0 1 6.093 3.65 17.928 17.928 0 0 1 1.846 1.94 18.765 18.765 0 0 1 1.065 1.436 19.119 19.119 0 0 1 .917 1.53 18.083 18.083 0 0 1 1.798 5.025 17.547 17.547 0 0 1 .263 1.772 18.517 18.517 0 0 1 .088 1.78c0 7.696-2.325 14.396-6.973 20.1-2.16 2.642-4.625 4.815-7.391 6.518l8.345 7.519a17.747 17.747 0 0 1 1.35 1.35 18.094 18.094 0 0 1 1.733 2.26 17.568 17.568 0 0 1 .955 1.654c.143.281.28.568.41.86a18.99 18.99 0 0 1 .68 1.774 17.503 17.503 0 0 1 .492 1.836 17.42 17.42 0 0 1 .299 1.887 18.948 18.948 0 0 1 .1 1.899c0 8.854-2.852 16.238-8.555 22.152-5.804 6.027-13.35 9.04-22.639 9.04h-34.659c-9.819 0-17.516-2.787-23.09-8.361a18.46 18.46 0 0 1-.572-.598c-5.02-5.499-7.531-12.91-7.531-22.233v-34.66c0-8.435 2.196-15.286 6.587-20.551 5.675-7.094 13.877-10.642 24.606-10.642zm47.657 6.066h-47.657c-8.785 0-15.408 2.788-19.87 8.365-3.505 4.204-5.257 9.792-5.257 16.763v34.659c0 7.755 1.981 13.803 5.945 18.144.123.135.25.268.38.398 4.39 4.39 10.658 6.586 18.802 6.586h34.66c7.568 0 13.658-2.394 18.269-7.183 4.572-4.741 6.858-10.723 6.858-17.945a12.623 12.623 0 0 0-.066-1.27 12.035 12.035 0 0 0-.347-1.87 12.688 12.688 0 0 0-.392-1.206 11.67 11.67 0 0 0-.517-1.162 11.738 11.738 0 0 0-.632-1.094c-.117-.18-.237-.356-.36-.526a12.976 12.976 0 0 0-.801-.989 12.125 12.125 0 0 0-.895-.894l-11.62-10.47a2.407 2.407 0 0 1-.211-.21c-.033-.037-.066-.074-.097-.112a2.895 2.895 0 0 1-.332-.493 3.077 3.077 0 0 1-.329-.984 3.47 3.47 0 0 1-.03-.295 2.467 2.467 0 0 1 0-.298 2.823 2.823 0 0 1 .053-.443 2.38 2.38 0 0 1 .071-.289 3.456 3.456 0 0 1 .1-.28 2.752 2.752 0 0 1 .2-.4 2.838 2.838 0 0 1 .255-.365l.097-.113a3.012 3.012 0 0 1 1.099-.774c3.56-1.466 6.61-3.752 9.153-6.859 3.734-4.583 5.601-10.003 5.601-16.26a12.636 12.636 0 0 0-.058-1.192 12.261 12.261 0 0 0-1.987-5.55 11.803 11.803 0 0 0-.709-.953 12.139 12.139 0 0 0-7.599-4.304 11.73 11.73 0 0 0-1.778-.131z"
      style={{
        fill: "#4ec3e0",
        fillOpacity: 1,
        strokeWidth: 3.82038,
        strokeDasharray: "none",
      }}
      transform="translate(-224 -426)"
    />
    <path
      d="M244.452 446.867c2.144-2.68 5.718-3.931 10.722-3.931h49.145c0 3.485-.983 6.433-2.95 8.846-2.412 2.949-5.896 4.468-10.454 4.557h-35.741v35.741h29.13l-18.497-16.62c3.396-3.306 6.612-5.003 9.472-5.093 3.038-.178 6.433 1.34 10.097 4.647l18.943 17.066c0 4.11-1.162 7.327-3.575 9.83-2.323 2.412-5.629 3.573-9.829 3.573h-35.741c-5.004 0-8.489-1.072-10.544-3.127-1.876-2.055-2.86-5.45-2.86-10.276V456.34c0-4.2.894-7.327 2.681-9.472z"
      style={{
        display: "inline",
        fill: "#ff5c00",
        fillOpacity: 1,
        strokeWidth: 3.9397,
        strokeDasharray: "none",
      }}
      transform="translate(-224 -426)"
    />
  </svg>
)

export default SvgComponent
